import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { ResponseErrorService } from '../errors/response-error.service';

import { WorkspaceApiProviderService } from '@core/api';
import { WindowService } from '@core/services';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { CustomFieldsApiProviderService } from '../../data-providers/api-providers/workspace-api-provider/custom-fields-api-provider.service';
import { TNewCustomField } from '../../data-providers/api-providers/workspace-api-provider/workspace-requests.model';
import { TSiteResponse } from '../workspace/site-response.model';
import { setChangedWorkspace } from '../workspace/workspace';
import { ECustomFieldType } from './custom-field-types-enums';
import {
  TAllCustomFields,
  TCustomField,
  TCustomFieldList,
  TWorkspaceCustomFields,
} from './custom-fields.model';
import { SET_CUSTOM_FIELDS } from './custom-fields.store';

export enum ECustomFieldsEventType {
  SAVE_CUSTOM_FIELD_CLICKED,
  ALPHABETIZE,
  ALL_SORT,
  FIELD_CHANGES,
  LEVEL_CHANGE,
}

type ISorted = {
  sorted: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class CustomFieldsService implements OnDestroy {
  private _saveCustomFieldClicked$ = new Subject<void>();
  private _alphabetize$ = new Subject<boolean>();
  private _allSort$ = new Subject<void>();
  private _fieldChanges$ = new Subject<void>();
  private _depthChange$ = new Subject<void>();
  private _maxListDepthChange$ = new BehaviorSubject<number>(3);

  saveCustomFieldClicked$ = this._saveCustomFieldClicked$.asObservable();
  alphabetize$ = this._alphabetize$.asObservable();
  allSort$ = this._allSort$.asObservable();
  fieldChanges$ = this._fieldChanges$.asObservable();
  depthChange$ = this._depthChange$.asObservable();
  maxListDepthChange$ = this._maxListDepthChange$.asObservable();

  private allSorted: ISorted = {
    sorted: false,
  };

  private readonly destroy$ = new Subject<void>();

  private customFields: TAllCustomFields = {};

  constructor(
    private responseErrorService: ResponseErrorService,
    private customFieldsApiProviderService: CustomFieldsApiProviderService,
    private workspaceApiProviderService: WorkspaceApiProviderService,
    private windowService: WindowService,
  ) {
    this.windowService.resize$.pipe(takeUntil(this.destroy$)).subscribe(({ innerWidth }) => {
      this.onResize(innerWidth);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  getCustomFields(): TAllCustomFields {
    return this.customFields;
  }

  setCustomFields(customFields: TAllCustomFields): TAllCustomFields {
    this.customFields = customFields;
    SET_CUSTOM_FIELDS(this.customFields);

    return this.customFields;
  }

  getWorkspaceCustomFields(workspaceId: string): TWorkspaceCustomFields {
    return this.customFields[workspaceId];
  }

  getWorkspaceCustomField(workspaceId: string, customFieldId: string): TCustomField {
    return this.customFields[workspaceId]?.[customFieldId]; // ?. is used because with asset management it's now possible to get CF ID for workspace user can't access
  }

  setWorkspaceCustomFields(workspaceId: string, customFields: TWorkspaceCustomFields): void {
    this.customFields[workspaceId] = customFields;

    SET_CUSTOM_FIELDS(this.customFields);
  }

  clearCustomFields(): TAllCustomFields {
    this.customFields = {};
    SET_CUSTOM_FIELDS(this.customFields);

    return this.getCustomFields();
  }

  addWorkspaceCustomField(workspaceId: string, customField: TCustomField): void {
    if (!this.customFields[workspaceId]) {
      this.customFields[workspaceId] = {};
    }

    this.customFields[workspaceId][customField.id.toString()] = customField;

    SET_CUSTOM_FIELDS(this.customFields);
  }

  updateWorkspaceCustomField(workspaceId: string, customField: TCustomField): void {
    this.customFields[workspaceId][customField.id.toString()] = customField;

    setChangedWorkspace(workspaceId);

    SET_CUSTOM_FIELDS(this.customFields);
  }

  updateWorkspaceCustomFieldVisibility(
    workspaceId: string,
    customFieldId: string,
    display: boolean,
  ): void {
    this.customFields[workspaceId][customFieldId].display = display;

    setChangedWorkspace(workspaceId);

    SET_CUSTOM_FIELDS(this.customFields);
  }

  addCustomField(workspaceId: string, customField: TNewCustomField): Observable<TCustomField> {
    return this.workspaceApiProviderService
      .addCustomField(workspaceId, customField)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  updateCustomField(
    workspaceId: string,
    customField: Partial<TCustomField>,
  ): Observable<TCustomField> {
    return this.workspaceApiProviderService
      .updateCustomField(workspaceId, customField)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  deleteCustomField(workspaceId: string, customFieldId: string): Observable<null> {
    return this.workspaceApiProviderService.deleteCustomField(workspaceId, customFieldId).pipe(
      tap(() => {
        delete this.customFields[workspaceId][customFieldId];

        SET_CUSTOM_FIELDS(this.customFields);
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  importCustomField(workspaceId: string, excel: File): Observable<TCustomField> {
    const formData = new FormData();

    formData.append('the_file', excel);

    return this.customFieldsApiProviderService
      .importCustomFieldsFromExcel(workspaceId, formData)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  reorderCustomFields(
    workspaceId: string,
    orderedCustomFieldIds: string[],
  ): Observable<TSiteResponse> {
    return this.workspaceApiProviderService
      .reorderCustomFields(workspaceId, orderedCustomFieldIds)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  setAllSorted(allSorted: boolean): void {
    this.allSorted.sorted = allSorted;
  }

  // TODO Refactor sorting logic. Avoid using object link
  getAllSorted(): ISorted {
    return this.allSorted;
  }

  onResize(innerWidth: number): void {
    let level = 1;

    if (innerWidth > 600) {
      level = 3;
    } else if (innerWidth > 500) {
      level = 2;
    }

    if (level !== this._maxListDepthChange$.value) {
      this._maxListDepthChange$.next(level);
    }
  }

  generateCustomFieldRequestData(customField: TCustomField): TNewCustomField {
    const oldFieldDataJSON = JSON.stringify(customField);
    const fieldLabel = ',"fieldError":false';
    const newFieldDataJSON = oldFieldDataJSON.split(fieldLabel).join('');
    const newFieldData = JSON.parse(newFieldDataJSON);

    return this.generateNewCFBody(customField, newFieldData);
  }

  emit(event: ECustomFieldsEventType, emittedObject?: boolean): void {
    switch (event) {
      case ECustomFieldsEventType.ALL_SORT:
        this._allSort$.next();
        break;
      case ECustomFieldsEventType.ALPHABETIZE:
        this._alphabetize$.next(emittedObject);
        break;
      case ECustomFieldsEventType.FIELD_CHANGES:
        this._fieldChanges$.next();
        break;
      case ECustomFieldsEventType.LEVEL_CHANGE:
        this._depthChange$.next();
        break;
      case ECustomFieldsEventType.SAVE_CUSTOM_FIELD_CLICKED:
        this._saveCustomFieldClicked$.next();
        break;
    }
  }

  getCustomFieldListDepth(fieldList: TCustomFieldList[]): number {
    return (
      1 +
      Math.max(
        0,
        ...fieldList.map((field) => {
          if (field.subList && field.subList.length) {
            this.getCustomFieldListDepth(field.subList);
          }
          return 0;
        }),
      )
    );
  }

  private generateNewCFBody(
    customField: TCustomField,
    newFieldData: TCustomField,
  ): TNewCustomField {
    const commonFields: TNewCustomField = {
      label: newFieldData.label,
      type: newFieldData.type,
    };

    switch (customField.type) {
      case ECustomFieldType.TEXT:
      case ECustomFieldType.RICHTEXT:
      case ECustomFieldType.CHECKBOX:
      case ECustomFieldType.TIMELINE:
      case ECustomFieldType.DATE:
        return commonFields;
      case ECustomFieldType.LIST:
        return {
          ...commonFields,
          subList: newFieldData.subList,
        };
      case ECustomFieldType.TIME:
        return {
          ...commonFields,
          showTotal: newFieldData.showTotal,
          showHoursOnly: newFieldData.showHoursOnly,
        };
      case ECustomFieldType.COST:
        return {
          ...commonFields,
          showTotal: newFieldData.showTotal,
          currencyCode: newFieldData.currencyCode,
          decimalPlaces: '2',
          subValuesActive: newFieldData.subValuesActive,
        };
      case ECustomFieldType.NUMBERS:
        return {
          ...commonFields,
          showTotal: newFieldData.showTotal,
          showCommas: newFieldData.showCommas,
          decimalPlaces: newFieldData.decimalPlaces.toString(),
          unit: newFieldData.unit,
          subValuesActive: newFieldData.subValuesActive,
        };
      case ECustomFieldType.PERCENTAGE:
        return {
          ...commonFields,
          decimalPlaces: '0',
          subValuesActive: newFieldData.subValuesActive,
        };
      case ECustomFieldType.FORMULA:
        switch (newFieldData.outputType) {
          case ECustomFieldType.COST:
            return {
              ...commonFields,
              showTotal: newFieldData.showTotal,
              currencyCode: newFieldData.currencyCode,
              formula: newFieldData.formula,
              outputType: newFieldData.outputType,
              decimalPlaces: '2',
            };
          case ECustomFieldType.NUMBERS:
            return {
              ...commonFields,
              showTotal: newFieldData.showTotal,
              showCommas: newFieldData.showCommas,
              decimalPlaces: newFieldData.decimalPlaces
                ? newFieldData.decimalPlaces.toString()
                : '0',
              unit: newFieldData.unit,
              formula: newFieldData.formula,
              outputType: newFieldData.outputType,
            };
          case ECustomFieldType.PERCENTAGE:
            return {
              ...commonFields,
              decimalPlaces: '0',
              formula: newFieldData.formula,
              outputType: newFieldData.outputType,
            };
        }

      default:
        throw new Error('Incorrect custom field type');
    }
  }
}
