import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { TAutoNumericOptions } from 'src/app/core/helpers/create-autonumeric';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { checkClearShortcut } from '../utils/check-clear-shortcut';

@Component({
  selector: 'pp-custom-field-percentage',
  templateUrl: './custom-field-percentage.component.html',
  styleUrls: ['./custom-field-percentage.component.scss'],
})
export class CustomFieldPercentageComponent implements AfterViewInit {
  @Input() ppField: TPointCustomField;
  @ViewChild('input', { static: false }) input: ElementRef;

  @Input() ppValue: number;
  @Input() ppCanEdit: boolean;
  @Input() ppUpdating: boolean | string = false;
  @Output() ppUpdate = new EventEmitter();
  @Input() ppWorkspaceId = '';
  @Input() ppFieldId = '';

  value: number;

  autonumericOptions: TAutoNumericOptions = {
    modifyValueOnWheel: false,
    decimalPlaces: 0,
    decimalPlacesRawValue: 0,
    formulaMode: true,
    watchExternalChanges: true,
  };

  constructor() {}

  ngAfterViewInit(): void {
    this.value = this.ppValue;
  }

  clearCustomField(): void {
    this.input.nativeElement.blur();
    this.value = null;

    this.updateField(null);
  }

  updateField(value: string): void {
    this.value = value !== null ? +value : null;

    if (this.ppUpdate) {
      const value = this.value || null;

      this.ppUpdate.emit(value);
    }
  }

  blurInput(event: Event): void {
    (event.target as HTMLElement).blur();
  }

  onKeyDown(event: KeyboardEvent): void {
    checkClearShortcut(event, () => this.clearCustomField());
  }
}
