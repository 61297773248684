<section class="subfieldValue" [class.pointCF__input--focus]="focused">
  <div class="subfield__inner">
    <section
      ppTooltip
      [ppTitle]="ppUnit"
      [ppTrimTooltip]="true"
      class="subfield__unit"
      [class.subfield__unit--hidden]="!ppUnit"
    >
      {{ ppUnit }}

      <pp-calculator-icon *ngIf="ppCanEdit"></pp-calculator-icon>
    </section>

    <input
      *ngIf="ppCanEdit"
      [ppAutonumeric]="autonumericOptions"
      #subfieldValue
      class="subfield__value"
      [(ngModel)]="ppValue"
      (ngModelChange)="onValueChange($event)"
      [placeholder]="placeholder"
      (ppFormatted)="updateField($event)"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (keydown)="onKeyDown($event)"
      (keydown.enter)="blurInput($event)"
    />

    <input
      *ngIf="!ppCanEdit"
      [ppAutonumeric]="autonumericOptions"
      #subfieldValue
      class="subfield__value"
      [(ngModel)]="ppValue"
      [placeholder]="placeholder"
      disabled
      readonly
    />
  </div>
</section>
