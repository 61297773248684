import {
  Component,
  DoCheck,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ClickOutsideHandler } from '@core/services';
import { Store } from '@ngrx/store';
import { TPointCustomField, TPointCustomFieldSubfield, TPointUpdate } from '@project/view-models';
import { cloneDeep, isEqual } from 'lodash';
import { catchError, finalize, of, Subject, takeUntil, tap, timer } from 'rxjs';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from 'src/app/project/components/confirm-modal/confirm-modal.component';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { SitePointFilterService } from '../../filters/site-point-filter.service';
import { PointFieldsService } from '../../points/point-modal/point-fields/point-fields.service';
import { PointActivityService } from '../../points/point-modal/point-timeline/point-activity.service';
import { PointsUpdateService } from '../../points/points-update.service';
import { UpdatePointCustomField } from '../../points/points.actions';
import { TPoint } from '../../points/points.model';
import { ECustomFieldType } from '../custom-field-types-enums';
import { TCustomField } from '../custom-fields.model';
import { CustomFieldsService } from '../custom-fields.service';

@Component({
  selector: 'pp-custom-field-numeric-with-subfields',
  templateUrl: './custom-field-numeric-with-subfields.component.html',
  styleUrl: './custom-field-numeric-with-subfields.component.scss',
})
export class CustomFieldNumericWithSubfieldsComponent implements OnChanges, DoCheck, OnDestroy {
  @Input() ppPointField: TPointCustomField;
  @Input() ppFieldId: string;
  @Input() ppWorkspaceId: string;
  @Input() ppPointId: string;
  @Input() ppCanEdit: boolean;
  @Input() ppNew: boolean;
  @ViewChild('subfieldsElement', { static: false }) subfieldsElement: ElementRef;
  private readonly destroy$ = new Subject<void>();

  customField: TCustomField;
  subfields: TPointCustomFieldSubfield[];
  unit: string;
  collapsed: boolean = true;
  addSubfieldButtonDisabled: boolean;
  updating: boolean;
  private subfieldsCopy: TPointCustomFieldSubfield[];
  private cancelUpdateField$ = new Subject<void>();
  private updateFieldTimerMs = 500;
  private clickOutsideHandler: ClickOutsideHandler;

  constructor(
    private customFieldsService: CustomFieldsService,
    private pointsUpdateService: PointsUpdateService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private pointActivityService: PointActivityService,
    private sitePointFilterService: SitePointFilterService,
    private store: Store<{ points: TPoint[] }>,
    private modalService: ModalService,
    private pointFieldsService: PointFieldsService,
  ) {}

  ngDoCheck(): void {
    this.addSubfieldButtonDisabled = this.subfields.some((subfield) => subfield.ppNew);
  }

  ngOnChanges(): void {
    this.customField = this.customFieldsService.getWorkspaceCustomField(
      this.ppWorkspaceId,
      this.ppFieldId,
    );

    this.subfields = this.ppPointField.subValues || [];
    this.subfieldsCopy = cloneDeep(this.subfields);
    this.setUnit();
  }

  ngAfterViewInit(): void {
    this.clickOutsideHandler = new ClickOutsideHandler(
      this.subfieldsElement.nativeElement,
      this.destroy$,
    );
    this.clickOutsideHandler.caught$.subscribe((event) => {
      this.onClickOutside(event);
    });

    this.clickOutsideHandler.enable();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  addSubfield(): void {
    this.subfields.push({
      value: null,
      description: '',
      ppNew: true,
    });
  }

  subfieldTrackByName(index: number, item: TPointCustomFieldSubfield): number {
    return item.id;
  }

  updateField(subfield: TPointCustomFieldSubfield, index: number): void {
    this.subfields[index] = subfield;

    this.update();
  }

  deleteSubfield(index: number): void {
    this.subfields.splice(index, 1);

    this.update();
  }

  toggleCollapse(): void {
    this.collapsed = !this.collapsed;
  }

  private onClickOutside(event: MouseEvent): void {
    if (isEqual(this.subfields, this.subfieldsCopy)) {
      return;
    }

    event.preventDefault();
    event.stopImmediatePropagation();

    const modalData: TConfirmModalParams = {
      message: this.translationPipe.transform('close_cf_without_saving_confirm'),
      heading: this.translationPipe.transform('confirm'),
      redButton: true,
      confirmText: this.translationPipe.transform('close_without_saving'),
    };

    this.modalService.setData(modalData);
    this.clickOutsideHandler.disable();

    this.modalService.showModal(ConfirmModalComponent, {
      blur: false,
      closeWarning: true,
      onClose: (succeeded, closeTransitionPromise) => {
        closeTransitionPromise.finally(() => {
          this.clickOutsideHandler.enable();
        });
      },
      callback: () => {
        this.subfields = cloneDeep(this.subfieldsCopy);

        if (this.ppNew) {
          this.updateFieldDeferred(
            this.ppPointId,
            this.ppFieldId,
            this.subfields,
            this.ppWorkspaceId,
          );
        }
      },
    });
  }

  private update(): void {
    const _id = this.ppPointId;
    const fieldId = this.ppFieldId;
    const subfields = this.subfields;
    const workspaceId = this.ppWorkspaceId;

    this.cancelUpdateField$.next();

    timer(this.updateFieldTimerMs)
      .pipe(
        takeUntil(this.cancelUpdateField$),
        tap(() => {
          this.updateFieldDeferred(_id, fieldId, subfields, workspaceId);
        }),
      )
      .subscribe();
  }

  private updateFieldDeferred(
    _id: string,
    fieldId: string,
    subfields: TPointCustomFieldSubfield[],
    workspaceId: string,
  ): void {
    const subfieldsForUpdate = subfields
      .filter((subfield) => {
        return !subfield.ppNew || subfield.value;
      })
      .map((subfield) => ({
        // Remove ppNew from model before API call
        value: subfield.value
          ? parseFloat(subfield.value.toString().replace(/,/g, '').replace(/%/g, '').trim())
          : 0,
        description: subfield.description,
        id: subfield.id,
      }));

    if (this.ppNew) {
      this.updateFieldForNewPoint(_id, fieldId, subfieldsForUpdate, workspaceId);

      return;
    }

    this.updating = true;

    const body: TPointUpdate = {
      customFieldsList: [
        {
          customFieldTemplateId: this.ppFieldId,
          subValues: subfieldsForUpdate,
        },
      ],
    };

    this.pointsUpdateService
      .updatePointField(_id, body)
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          const promptText = this.translationPipe.transform('prompt_point_custom_field_update');

          this.promptService.showSuccess(promptText);
          this.pointActivityService.refreshTimeline(workspaceId, _id);
          this.sitePointFilterService.filterPoints({ _keepScrollPosition: true });
        }),
        catchError((error) => {
          this.pointFieldsService.showUpdatePointFieldError(error);

          return of();
        }),
        finalize(() => {
          this.updating = false;
        }),
      )
      .subscribe();
  }

  private updateFieldForNewPoint(
    pointId: string,
    fieldId: string,
    subfields: TPointCustomFieldSubfield[],
    workspaceId: string,
  ): void {
    this.store.dispatch(
      new UpdatePointCustomField({
        workspaceId: workspaceId,
        pointId: pointId,
        customFieldId: fieldId,
        subfields: subfields,
        customFieldValue: subfields.reduce((acc, subfield) => {
          return +acc + +subfield.value;
        }, 0),
      }),
    );
  }

  private setUnit(): void {
    switch (this.customField.type) {
      case ECustomFieldType.PERCENTAGE:
        this.unit = '%';
        break;
      case ECustomFieldType.COST:
        this.unit = this.customField.currencyCode + ' ' + this.customField.currencySymbol;
        break;
      case ECustomFieldType.NUMBERS:
        this.unit = this.customField.unit;
        break;
    }
  }
}
