<span class="pointCF__currency-symbol"> % </span>

<input
  #input
  [class.pointCF__input--disabled]="!ppCanEdit"
  [ppAutonumeric]="autonumericOptions"
  class="pointCF__input pointCF__input--currency"
  [class.pointCF__input--error]="ppUpdating === 'failed'"
  [(ngModel)]="value"
  (ppFormatted)="updateField($event)"
  placeholder=" --"
  (keydown.enter)="blurInput($event)"
  (keydown)="onKeyDown($event)"
/>

<button class="pointCF__clear" (click)="clearCustomField()" *ngIf="ppCanEdit && !ppUpdating">
  &#xd7;
</button>
