<div class="pointCF__field">
  <label
    ppTooltip
    class="pointCF__label"
    ppTitle="{{ ppCustomField?.label }}"
    [ppTrimTooltip]="true"
  >
    <img class="pointCF__bulk-changes-field-icon" [src]="EIconPath.CUSTOM_FIELD_NUMBERS_ACTIVITY" />

    <span class="pointCF__label-container">
      {{ ppCustomField?.label }}
    </span>
  </label>

  <div
    class="pointCF__content"
    ppTooltip
    [ppTitle]="
      ppCustomField.subValuesActive
        ? ('prompt_bulk_changes_subfields_edit_disabled' | ppTranslate)
        : ''
    "
  >
    <pp-custom-field-numbers
      [ppCanEdit]="!ppCustomField.subValuesActive"
      [ppField]="ppCustomField"
      [ppWorkspaceId]="ppWorkspaceId"
      [ppFieldId]="ppFieldId"
      [ppValue]="ppCustomFields[ppFieldId]"
      (ppUpdate)="updateNumbers($event, ppCustomField)"
    ></pp-custom-field-numbers>
  </div>
</div>
