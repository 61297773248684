import { Injectable } from '@angular/core';
import { TWorkspace } from '../../workspace/workspace.model';
import { ECustomFieldType } from '../custom-field-types-enums';
import { TCustomField } from '../custom-fields.model';
import { CustomFieldsService } from '../custom-fields.service';
import { compareLists } from '../utils/compare-lists';
import { TCombinedCustomField } from './combined-custom-field.model';

@Injectable({
  providedIn: 'root',
})
export class CombineCustomFieldsService {
  constructor(private customFieldService: CustomFieldsService) {}

  combineCustomFields(workspaces: TWorkspace[]): TCombinedCustomField[] {
    const combinedCustomFields: TCombinedCustomField[] = [];

    workspaces.forEach((workspace) => {
      const workspaceCustomFields =
        this.customFieldService.getCustomFields()[workspace.workspaceId];

      if (workspaceCustomFields) {
        Object.keys(workspaceCustomFields).forEach((customFieldId) => {
          const display = this.customFieldService.getWorkspaceCustomField(
            workspace.workspaceId,
            customFieldId,
          ).display;

          if (!display) {
            delete workspaceCustomFields[customFieldId];
          }
        });

        Object.keys(workspaceCustomFields).forEach((customFieldId) => {
          const customField = workspaceCustomFields[customFieldId];
          const match: TCombinedCustomField = this.compareCustomFields(
            customField,
            combinedCustomFields,
          );

          if (match) {
            match.combinedIds.push({
              workspaceId: workspace.workspaceId,
              customFieldId,
            });
          } else {
            combinedCustomFields.push({
              ...customField,
              combinedIds: [
                {
                  workspaceId: workspace.workspaceId,
                  customFieldId,
                },
              ],
            });
          }
        });
      }
    });

    return combinedCustomFields;
  }

  private compareCustomFields(
    customField: TCustomField,
    combinedCustomFields: TCombinedCustomField[],
  ): TCombinedCustomField {
    switch (customField.type) {
      case ECustomFieldType.DATE:
        return this.checkDateCustomField(combinedCustomFields, customField);
      case ECustomFieldType.COST:
        return this.checkCostCustomField(combinedCustomFields, customField);
      case ECustomFieldType.LIST:
        return this.checkListCustomField(combinedCustomFields, customField);
      case ECustomFieldType.NUMBERS:
        return this.checkNumbersCustomField(combinedCustomFields, customField);
      case ECustomFieldType.TIME:
        return this.checkTimeCustomField(combinedCustomFields, customField);
      case ECustomFieldType.TIMELINE:
        return this.checkTimelineCustomField(combinedCustomFields, customField);
      case ECustomFieldType.PERCENTAGE:
        return this.checkPercentageCustomField(combinedCustomFields, customField);
      case ECustomFieldType.CHECKBOX:
        return this.checkCheckboxCustomField(combinedCustomFields, customField);
      case ECustomFieldType.TEXT:
        return this.checkTextCustomField(combinedCustomFields, customField);
      case ECustomFieldType.RICHTEXT:
        return this.checkRichTextCustomField(combinedCustomFields, customField);
      case ECustomFieldType.FORMULA:
        return this.checkFormulaCustomField(combinedCustomFields, customField);
      default:
        throw new Error('Custom field type not found ' + customField.type);
    }
  }

  private checkDateCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return this.compareByName(combinedCustomFields, customField);
  }

  private checkCostCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return combinedCustomFields.find(
      (combinedCustomField) =>
        combinedCustomField.label === customField.label &&
        combinedCustomField.type === customField.type &&
        combinedCustomField.currencyCode === customField.currencyCode &&
        combinedCustomField.subValuesActive === customField.subValuesActive,
    );
  }

  private checkListCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return combinedCustomFields.find((combinedCustomField) => {
      if (
        combinedCustomField.type !== customField.type ||
        combinedCustomField.label !== customField.label
      ) {
        return false;
      }

      return compareLists(combinedCustomField.subList, customField.subList);
    });
  }

  private checkNumbersCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return combinedCustomFields.find(
      (combinedCustomField) =>
        combinedCustomField.label === customField.label &&
        combinedCustomField.type === customField.type &&
        combinedCustomField.unit === customField.unit &&
        combinedCustomField.subValuesActive === customField.subValuesActive,
    );
  }

  private checkTimeCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return this.compareByName(combinedCustomFields, customField);
  }

  private checkTimelineCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return this.compareByName(combinedCustomFields, customField);
  }

  private checkPercentageCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return this.compareByName(combinedCustomFields, customField);
  }

  private checkCheckboxCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return this.compareByName(combinedCustomFields, customField);
  }

  private checkTextCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return this.compareByName(combinedCustomFields, customField);
  }

  private checkFormulaCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return null; // TODO Do we want to merge formula fields?
  }

  private checkRichTextCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return this.compareByName(combinedCustomFields, customField);
  }

  private compareByName(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return combinedCustomFields.find(
      (combinedCustomField) =>
        combinedCustomField.label === customField.label &&
        combinedCustomField.type === customField.type,
    );
  }
}
