import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TPointCustomFieldSubfield } from '@project/view-models';
import { ECustomFieldSubfieldDeleteButtonState } from './custom-field-subfield-delete-button/custom-field-subfield-delete-button.consts';

@Component({
  selector: 'pp-custom-field-numeric-with-subfields-subfield',
  templateUrl: './custom-field-numeric-with-subfields-subfield.component.html',
  styleUrl: './custom-field-numeric-with-subfields-subfield.component.scss',
})
export class CustomFieldNumericWithSubfieldsSubfieldComponent {
  @ViewChild('subfieldValue', { static: false }) costInputElement: ElementRef;
  @ViewChild('subfieldElement', { static: false }) customFieldElement: ElementRef;
  @Input() ppUnit: string;
  @Input() ppSubfield: TPointCustomFieldSubfield;
  @Input() ppCanEdit: boolean;
  @Input() ppDecimalPlaces: number;
  @Output() ppUpdate = new EventEmitter<TPointCustomFieldSubfield>();
  @Output() ppDelete = new EventEmitter<void>();

  saveButtonState: ECustomFieldSubfieldDeleteButtonState;
  ECustomFieldSubfieldDeleteButtonState = ECustomFieldSubfieldDeleteButtonState;
  focused: boolean;
  touched: boolean;
  filled: boolean;

  ngDoCheck(): void {
    if (this.ppSubfield.ppNew) {
      this.saveButtonState = this.filled
        ? ECustomFieldSubfieldDeleteButtonState.CONFIRM
        : this.focused && this.touched
          ? ECustomFieldSubfieldDeleteButtonState.CONFIRM_DISABLED
          : ECustomFieldSubfieldDeleteButtonState.DELETE;
    } else {
      this.saveButtonState = ECustomFieldSubfieldDeleteButtonState.DELETE;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ppSubfield && changes.ppSubfield.currentValue) {
      this.touched = false;
      this.filled = this.ppSubfield.value !== null;
    }
  }

  onInputChange(event): void {
    this.filled = event.length > 0;
    this.touched = true;
    this.ppSubfield.ppNew = true;
  }

  onDescriptionChange(): void {
    this.touched = true;
    this.ppSubfield.ppNew = true;
  }

  saveSubfield(): void {
    this.ppUpdate.emit(this.ppSubfield);
  }

  updateValue(value: string): void {
    this.ppSubfield.value = +value;

    if (this.ppSubfield.ppNew) {
      return;
    }

    this.ppUpdate.emit(this.ppSubfield);
  }

  updateField(): void {
    if (this.ppSubfield.ppNew) {
      return;
    }

    this.ppUpdate.emit(this.ppSubfield);
  }

  markInputAsEdited(value: string): void {
    this.ppSubfield.value = +value;
    this.ppSubfield.ppNew = true;
  }

  markFieldAsEdited(): void {
    this.ppSubfield.ppNew = true;
  }

  deleteSubfield(): void {
    this.ppDelete.emit();
  }
}
