import { isEqual } from 'lodash';
import { TColumn } from '../../site/site-table/columns/column.model';

export function checkIfColumnMatches(column: TColumn, viewColumn: TColumn): boolean {
  if (!viewColumn || !column) {
    return true;
  }

  const nameMismatch = column.name !== viewColumn.name;
  const widthMismatch = column.width !== viewColumn.width;
  const hiddenMismatch = column.hidden !== viewColumn.hidden;
  const groupIndexMismatch = column.groupIndex !== viewColumn.groupIndex;
  const sortIndexMismatch = column.sortIndex !== viewColumn.sortIndex;
  const groupOrderMismatch = column.groupOrder !== viewColumn.groupOrder;
  const sortOrderMismatch = column.sortOrder !== viewColumn.sortOrder;
  const customFieldIdsMismatch =
    column.customFieldIds &&
    viewColumn.customFieldIds &&
    !isEqual(column.customFieldIds, viewColumn.customFieldIds);
  const showTotalMismatch =
    typeof column.showTotal !== 'undefined' &&
    typeof viewColumn.showTotal !== 'undefined' &&
    column.showTotal !== viewColumn.showTotal;
  const indexMismatch = column.index !== viewColumn.index;
  const currencyMismatch =
    typeof column.currency !== 'undefined' &&
    typeof viewColumn.currency !== 'undefined' &&
    column.currency !== viewColumn.currency;
  const decimalPlacesMismatch =
    typeof column.decimalPlaces !== 'undefined' &&
    typeof viewColumn.decimalPlaces !== 'undefined' &&
    column.decimalPlaces !== viewColumn.decimalPlaces;
  const unitMismatch = column.unit && viewColumn.unit && column.unit !== viewColumn.unit;
  const subValuesActiveMismatch =
    typeof column.subValuesActive !== 'undefined' &&
    typeof viewColumn.subValuesActive !== 'undefined' &&
    column.subValuesActive !== viewColumn.subValuesActive;

  return !(
    nameMismatch ||
    widthMismatch ||
    hiddenMismatch ||
    groupIndexMismatch ||
    sortIndexMismatch ||
    groupOrderMismatch ||
    sortOrderMismatch ||
    customFieldIdsMismatch ||
    showTotalMismatch ||
    indexMismatch ||
    currencyMismatch ||
    decimalPlacesMismatch ||
    unitMismatch ||
    subValuesActiveMismatch
  );
}
