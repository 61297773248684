<div class="pointCF__field pointCF__field--expandable">
  <label class="pointCF__label">
    <div class="pointCF__label-image--container">
      <pp-icon
        [ppSrc]="EIconPath.ICON_FIELDS_PERCENTAGE_18"
        ppColor="inherit"
        ppClass="setSize18"
      />
    </div>

    <span
      ppTooltip
      class="pointCF__label-container"
      ppTitle="{{ ppFieldLabel }}"
      [ppTrimTooltip]="true"
    >
      {{ ppFieldLabel }}
    </span>
  </label>

  <pp-custom-field-numeric-with-subfields
    [ppPointField]="ppField"
    [ppFieldId]="ppFieldId"
    [ppWorkspaceId]="ppWorkspaceId"
    [ppCanEdit]="ppCanEdit"
    [ppPointId]="ppPointId"
    [ppNew]="ppNew"
    *ngIf="ppSubfieldsActive"
  ></pp-custom-field-numeric-with-subfields>

  <div class="pointCF__content" #customFieldElement *ngIf="!ppSubfieldsActive">
    <span class="pointCF__currency-symbol">
      %
      <pp-calculator-icon *ngIf="ppCanEdit"></pp-calculator-icon>
    </span>

    <input
      #percentageInput
      [ppAutonumeric]="autonumericOptions"
      class="pointCF__input pointCF__input--currency"
      [(ngModel)]="value"
      [class.pointCF__input--disabled]="!ppCanEdit"
      [attr.disabled]="ppCanEdit ? null : true"
      [attr.readonly]="ppCanEdit ? null : true"
      [class.pointCF__input--focus]="focused"
      (ppFormatted)="updateField($event)"
      (focus)="onFocus()"
      (blur)="onBlur()"
      placeholder=" --"
      (keydown.enter)="blurInput($event)"
      (keydown)="onKeyDown($event)"
    />

    <pp-save-indicator
      [ppFocused]="focused"
      [ppFilled]="value?.length > 0"
      [ppUpdating]="updating"
      [ppShowClearBtn]="ppCanEdit"
      (ppAction)="clearCustomField()"
    ></pp-save-indicator>
  </div>
</div>
