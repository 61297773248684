import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TAutoNumericOptions } from 'src/app/core/helpers/create-autonumeric';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { CustomFieldsService } from '../custom-fields.service';
import { checkClearShortcut } from '../utils/check-clear-shortcut';
import { getDecimalPlacesPlaceholder } from './utils/getDecimalPlacesPlaceholder';
import { getDecimalPlacesValue } from './utils/getDecimalPlacesValue';

@Component({
  selector: 'pp-custom-field-numbers',
  templateUrl: './custom-field-numbers.component.html',
  styleUrls: ['./custom-field-numbers.component.scss'],
})
export class CustomFieldNumbersComponent implements OnInit, AfterViewInit {
  @Input() ppField: TPointCustomField;
  @ViewChild('input', { static: false }) input: ElementRef;

  @Input() ppValue: number;
  @Input() ppUpdating: boolean | string = false;
  @Input() ppWorkspaceId = '';
  @Input() ppFieldId = '';
  @Input() ppCanEdit: boolean;

  @Output() ppUpdate = new EventEmitter();

  value: number;
  decimalPlaces = 2;
  showCommas: boolean;
  placeholder = '---.--';
  autonumericOptions: TAutoNumericOptions;

  public unit = '';

  constructor(
    private customFieldsService: CustomFieldsService,
    private ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    const customField =
      this.customFieldsService.getCustomFields()[this.ppWorkspaceId]?.[this.ppFieldId];

    if (customField) {
      this.unit = customField.unit;
      this.showCommas = customField.showCommas;
      this.decimalPlaces = getDecimalPlacesValue(customField.decimalPlaces);

      if (this.decimalPlaces !== 2) {
        this.placeholder = getDecimalPlacesPlaceholder(this.decimalPlaces);
      }
    }

    this.autonumericOptions = {
      modifyValueOnWheel: false,
      decimalPlaces: this.decimalPlaces,
      decimalPlacesRawValue: this.decimalPlaces,
      digitGroupSeparator: this.showCommas ? ',' : '',
      formulaMode: true,
      watchExternalChanges: true,
    };
  }

  ngAfterViewInit(): void {
    this.value = this.ppValue;
  }

  clearCustomField(): void {
    this.input.nativeElement.blur();

    this.value = null;

    this.updateField(null);
  }

  onKeyDown(event: KeyboardEvent): void {
    checkClearShortcut(event, () => this.clearCustomField());
  }

  updateField(value: string): void {
    this.value = value !== null ? +value : null;

    if (this.ppUpdate) {
      const value = this.value || null;

      this.ppUpdate.emit(value);
    }
  }

  blurInput(event: Event): void {
    (event.target as HTMLElement).blur();
  }
}
