<div class="pointCF__field pointCF__field--expandable">
  <label class="pointCF__label">
    <div class="pointCF__label-image--container">
      <pp-icon [ppSrc]="EIconPath.ICON_FIELDS_NUMBERS_18" ppColor="inherit" ppClass="setSize18" />
    </div>

    <span
      ppTooltip
      class="pointCF__label-container"
      ppTitle="{{ ppFieldLabel }}"
      [ppTrimTooltip]="true"
    >
      {{ ppFieldLabel }}
    </span>
  </label>

  <pp-custom-field-numeric-with-subfields
    [ppPointField]="ppField"
    [ppFieldId]="ppFieldId"
    [ppWorkspaceId]="ppWorkspaceId"
    [ppCanEdit]="ppCanEdit"
    [ppPointId]="ppPointId"
    [ppNew]="ppNew"
    *ngIf="ppSubfieldsActive"
  ></pp-custom-field-numeric-with-subfields>

  <div class="pointCF__content" #customFieldElement *ngIf="!ppSubfieldsActive">
    <span ppTooltip class="pointCF__currency-symbol" [ppTitle]="unit" [ppTrimTooltip]="true">
      <span class="pointCF__currency--unit">
        {{ unit ? unit : ' ' }}
      </span>

      <pp-calculator-icon *ngIf="ppCanEdit"></pp-calculator-icon>
    </span>

    <input
      #numbersInput
      [ppAutonumeric]="autonumericOptions"
      class="pointCF__input pointCF__input--currency"
      [class.pointCF__input--focus]="focused"
      [(ngModel)]="value"
      (ppFormatted)="updateField($event)"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [class.pointCF__input--disabled]="!ppCanEdit"
      [attr.disabled]="ppCanEdit ? null : true"
      [attr.readonly]="ppCanEdit ? null : true"
      [placeholder]="placeholder"
      (keydown.enter)="blurInput($event)"
      (keydown)="onKeyDown($event)"
    />

    <pp-save-indicator
      [ppFocused]="focused"
      [ppFilled]="value?.length > 0"
      [ppUpdating]="updating"
      [ppShowClearBtn]="ppCanEdit"
      (ppAction)="clearCustomField()"
    ></pp-save-indicator>
  </div>
</div>
