import { Component, Input, OnChanges } from '@angular/core';
import { formatCurrency } from 'src/app/core/helpers/format-currency';
import { getDecimalPlacesPlaceholder } from '../../custom-field-numbers/utils/getDecimalPlacesPlaceholder';
import { TCustomField } from '../../custom-fields.model';
import { CustomFieldsService } from '../../custom-fields.service';

@Component({
  selector: 'pp-custom-fields-numeric-with-subfields-total',
  templateUrl: './custom-fields-numeric-with-subfields-total.component.html',
  styleUrl: './custom-fields-numeric-with-subfields-total.component.scss',
})
export class CustomFieldsNumericWithSubfieldsTotalComponent implements OnChanges {
  @Input() ppValue: string;
  @Input() ppFieldId: string;
  @Input() ppWorkspaceId: string;
  @Input() ppUnit: string;

  formattedValue: string;
  placeholder: string;
  private customField: TCustomField;

  constructor(private customFieldsService: CustomFieldsService) {}

  ngOnChanges(): void {
    this.customField = this.customFieldsService.getWorkspaceCustomField(
      this.ppWorkspaceId,
      this.ppFieldId,
    );

    this.placeholder = getDecimalPlacesPlaceholder(+this.customField.decimalPlaces);

    this.setValue();
  }

  private setValue(): void {
    this.formattedValue = formatCurrency(
      parseFloat(this.ppValue),
      '',
      +this.customField.decimalPlaces,
    );
  }
}
