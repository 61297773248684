<span ppTooltip class="pointCF__currency-symbol" ppTitle="{{ unit }}" [ppTrimTooltip]="true">
  {{ unit }}
</span>

<input
  #input
  [class.pointCF__input--disabled]="!ppCanEdit"
  [ppAutonumeric]="autonumericOptions"
  class="pointCF__input pointCF__input--currency"
  [class.pointCF__input--error]="ppUpdating === 'failed'"
  [(ngModel)]="value"
  (ppFormatted)="updateField($event)"
  [attr.disabled]="ppCanEdit ? null : true"
  [attr.readonly]="ppCanEdit ? null : true"
  [placeholder]="placeholder"
  (keydown.enter)="blurInput($event)"
  (keydown)="onKeyDown($event)"
/>

<button class="pointCF__clear" (click)="clearCustomField()" *ngIf="!ppUpdating && ppCanEdit">
  &#xd7;
</button>
