import { Injectable } from '@angular/core';
import { cloneDeep, isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { EBasicField, EBasicFieldShort } from 'src/app/project/shared/enums/basic-fields-enums';
import { TCurrencyCode } from '../../custom-fields/currencies/currencies.consts';
import { ECustomFieldType } from '../../custom-fields/custom-field-types-enums';
import { TCustomField, TCustomFields } from '../../custom-fields/custom-fields.model';
import { GET_CUSTOM_FIELDS } from '../../custom-fields/custom-fields.store';
import { PreferencesService } from '../../preferences/preferences-service/preferences.service';
import { TWorkspacePreferences } from '../../preferences/preferences.model';
import { WorkspaceService } from '../../workspace/workspace.service';
import { EWorkspaces } from '../../workspace/workspaces.enum';
import { TColumn } from './columns/column.model';
import { TableColumnsService } from './columns/table-columns.service';
import { CustomTableService } from './custom-table/custom-table.service';

@Injectable({
  providedIn: 'root',
})
export class SiteTableService {
  private readonly _tableLoadChange$ = new Subject<boolean>();
  readonly tableLoadChange$ = this._tableLoadChange$.asObservable();
  private tableLoaded = false;

  constructor(
    private workspaceService: WorkspaceService,
    private customTableService: CustomTableService,
    private preferencesService: PreferencesService,
    private tableColumnsService: TableColumnsService,
  ) {}

  processPreferencesResponse(
    workspacePreferences: TWorkspacePreferences,
    workspaceId: string,
  ): void {
    const table = this.customTableService.getTable();
    const columns = this.tableColumnsService.getColumns();

    if (workspacePreferences?.columns) {
      this.updateColumnData(workspacePreferences, columns);
      this.removeEmptyColumns(workspacePreferences, workspaceId);
      this.checkExistingColumns(columns, workspacePreferences);
      this.removeCustomFieldsFromOtherWorkspaces(workspacePreferences);

      table.updateColumns({
        columns: cloneDeep(workspacePreferences.columns),
        updatePreferences: false,
      });

      this.preferencesService.setFiltersFromView(workspacePreferences.filters, workspaceId);
    } else {
      table.updateColumns();
    }
  }

  private removeCustomFieldsFromOtherWorkspaces(workspacePreferences: TWorkspacePreferences): void {
    const workspace = this.workspaceService.getActiveWorkspace();

    workspacePreferences.columns = workspacePreferences.columns.filter((column) => {
      if (!column.customFieldIds) {
        return true;
      }

      column.customFieldIds = column.customFieldIds.filter(
        (customFieldId) =>
          workspace.customFields.includes(customFieldId) &&
          GET_CUSTOM_FIELDS()[customFieldId].display,
      );

      return column.customFieldIds.length !== 0;
    });
  }

  private updateColumnData(workspacePreferences: TWorkspacePreferences, columns: TColumn[]): void {
    workspacePreferences.columns = workspacePreferences.columns.filter((column) => {
      if (columns.length === 0) {
        return true;
      }

      if (column.customFieldIds) {
        const existingColumn = columns.find((_column) => {
          let match = false;

          if (_column?.customFieldIds) {
            _column?.customFieldIds.forEach((fieldId) => {
              if (column.customFieldIds.includes(fieldId)) {
                match = true;
              }
            });

            return match;
          } else {
            return column.name === _column.name;
          }
        });

        if (existingColumn) {
          column.currency = existingColumn.currency;
          column.decimalPlaces = existingColumn.decimalPlaces;
          column.unit = existingColumn.unit;
          column.subValuesActive = existingColumn.subValuesActive;

          if (existingColumn.showTotal || typeof existingColumn.showTotal === 'undefined') {
            column.showTotal = true;
          }

          if (
            !existingColumn.subValuesActive ||
            typeof existingColumn.subValuesActive === 'undefined'
          ) {
            column.showTotal = false;
          }

          return true;
        }

        return false;
      } else {
        return true;
      }
    });
  }

  private removeEmptyColumns(
    workspacePreferences: TWorkspacePreferences,
    workspaceId: string,
  ): void {
    workspacePreferences.columns = workspacePreferences.columns.filter((column) => {
      if (!column.customFieldIds) {
        return true;
      } else {
        const customFields = GET_CUSTOM_FIELDS();

        this.filterCustomFieldIds(column, customFields, workspaceId);

        const existingColumns = workspacePreferences.columns.filter(
          (_column) =>
            _column.name === column.name &&
            _column.currency === column.currency &&
            _column.decimalPlaces === column.decimalPlaces &&
            !!_column.showTotal === !!column.showTotal &&
            _column.unit === column.unit &&
            _column.subValuesActive === column.subValuesActive,
        );

        if (existingColumns.length > 1) {
          if (!isEqual(existingColumns[0].customFieldIds, column.customFieldIds)) {
            existingColumns[0].customFieldIds = [
              ...existingColumns[0].customFieldIds,
              ...column.customFieldIds,
            ];

            return false;
          }
        }

        if (column.customFieldIds.length === 0) {
          return false;
        }

        return true;
      }
    });
  }

  private filterCustomFieldIds(
    column: TColumn,
    customFields: TCustomFields,
    workspaceId: string,
  ): void {
    column.customFieldIds = column.customFieldIds.filter((customFieldId) => {
      const fieldExists = !!customFields[customFieldId] && customFields[customFieldId]?.display;

      if (!fieldExists) {
        return false;
      }

      const customField = customFields[customFieldId];
      const nameMatches = this.checkNameMatches(customField, column, workspaceId);

      return nameMatches;
    });
  }

  private checkNameMatches(
    customField: TCustomField,
    column: TColumn,
    workspaceId: string,
  ): boolean {
    let nameMatches = true;

    const defaultWorkspaceId =
      workspaceId &&
      workspaceId !== EWorkspaces.OVERVIEW &&
      workspaceId !== EWorkspaces.TIMELINE &&
      workspaceId !== EWorkspaces.REMINDERS;

    switch (customField.type) {
      case ECustomFieldType.FORMULA:
      case ECustomFieldType.CHECKBOX:
      case ECustomFieldType.DATE:
      case ECustomFieldType.LIST:
      case ECustomFieldType.PERCENTAGE:
      case ECustomFieldType.RICHTEXT:
      case ECustomFieldType.TEXT:
      case ECustomFieldType.TIMELINE:
        nameMatches = customField.label === column.name;
        break;
      case ECustomFieldType.COST: {
        if (defaultWorkspaceId) {
          column.currency = customField.currencyCode as TCurrencyCode;
          column.showTotal = customField.showTotal;
          column.subValuesActive = !!customField.subValuesActive;
        }

        nameMatches =
          customField.label === column.name &&
          customField.currencyCode === column.currency &&
          customField.showTotal === column.showTotal &&
          !!customField.subValuesActive === !!column.subValuesActive;

        break;
      }
      case ECustomFieldType.NUMBERS:
        if (defaultWorkspaceId) {
          column.decimalPlaces = +customField.decimalPlaces;
          column.unit = customField.unit;
          column.showTotal = customField.showTotal;
          !!customField.subValuesActive === !!column.subValuesActive;
        }

        nameMatches =
          customField.label === column.name &&
          customField.unit === column.unit &&
          customField.decimalPlaces === column.decimalPlaces &&
          customField.showTotal === column.showTotal &&
          !!customField.subValuesActive === !!column.subValuesActive;
        break;
      case ECustomFieldType.TIME:
        if (defaultWorkspaceId) {
          column.showTotal = customField.showTotal;
        }

        nameMatches =
          customField.label === column.name && customField.showTotal === column.showTotal;
        break;
    }
    return nameMatches;
  }

  private checkExistingColumns(
    columns: TColumn[],
    workspacePreferences: TWorkspacePreferences,
  ): void {
    this.checkIfColumnNeedsCustomFields(workspacePreferences);

    columns.forEach((column) => {
      if (column.customFieldIds) {
        column.customFieldIds = column.customFieldIds.filter((customFieldId) => {
          const customField = GET_CUSTOM_FIELDS()[customFieldId];

          if (!customField) {
            return false;
          }

          return true;
        });

        const existingColumn = workspacePreferences.columns.find(
          (_column) =>
            _column.name === column.name &&
            _column.currency === column.currency &&
            _column.decimalPlaces === column.decimalPlaces &&
            !!_column.showTotal === !!column.showTotal &&
            _column.unit === column.unit &&
            !!_column.subValuesActive === !!column.subValuesActive,
        );

        if (!existingColumn) {
          const columnToMerge = workspacePreferences.columns.find(
            (_column) =>
              _column.name === column.name &&
              _column.currency === column.currency &&
              _column.unit === column.unit &&
              _column.subValuesActive === column.subValuesActive,
          );

          if (columnToMerge) {
            column.customFieldIds.forEach((fieldId) => {
              if (!columnToMerge.customFieldIds.includes(fieldId)) {
                columnToMerge.customFieldIds.push(fieldId);
              }
            });
          } else {
            workspacePreferences.columns.push(column);
          }
        } else {
          if (!existingColumn.customFieldIds.includes(column.customFieldIds[0])) {
            existingColumn.customFieldIds.push(column.customFieldIds[0]);
          }
        }
      }
    });
  }

  private checkIfColumnNeedsCustomFields(workspacePreferences: TWorkspacePreferences): void {
    workspacePreferences.columns.forEach((column) => {
      switch (column.name) {
        case EBasicField.ACCOUNT:
        case EBasicField.SITE:
        case EBasicField.PRIORITY:
        case EBasicFieldShort.PRIORITY:
        case EBasicField.STATUS:
        case EBasicFieldShort.STATUS:
        case EBasicField.SEQUENCE_NUMBER:
        case EBasicFieldShort.SEQUENCE_NUMBER:
        case EBasicField.TITLE:
        case EBasicField.DESCRIPTION:
        case EBasicField.CREATED:
        case EBasicField.UPDATED:
        case EBasicField.CREATED_BY:
        case EBasicField.ASSIGNEES:
        case EBasicField.TAGS:
        case EBasicField.FLAGGED:
        case EBasicFieldShort.FLAGGED:
          break;
        default:
          if (!column.customFieldIds) {
            column.customFieldIds = [];
          }
          break;
      }
    });
  }

  setTableLoaded(loaded: boolean): void {
    this._tableLoadChange$.next(loaded);
    this.tableLoaded = loaded;
  }

  getTableLoaded(): boolean {
    return this.tableLoaded;
  }
}
