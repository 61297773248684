import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

import { Store } from '@ngrx/store';

import { UpdatePointCustomField } from 'src/app/project/modules/points/points.actions';
import { TPoint } from 'src/app/project/modules/points/points.model';

import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PointsUpdateService } from 'src/app/project/modules/points/points-update.service';
import { PointsService } from 'src/app/project/modules/points/points.service';
import { PromptService } from '../../../../../components/prompt/prompt.service';
import { PointActivityService } from '../../point-timeline/point-activity.service';
import { PointFieldsService } from '../point-fields.service';

import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';

import { TPointCustomField, TPointUpdate } from '@project/view-models';
import { of, Subject, timer } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { trimNumericInput } from 'src/app/core/helpers/trim-numeric-input';
import { EIntegrationStatus } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { CustomTableService } from 'src/app/project/modules/site/site-table/custom-table/custom-table.service';
import { EIconPath } from '../../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-point-fields-cost',
  templateUrl: './point-fields-cost.component.html',
  styleUrls: ['./point-fields-cost.component.scss', '../point-fields.component.scss'],
})
export class PointFieldsCostComponent implements OnChanges, OnDestroy {
  @Input() ppWorkspaceId: string;
  @Input() ppPointId: string;
  @Input() ppFieldId: string;
  @Input() ppFieldLabel: string;
  @Input() ppFieldCurrencySymbol: string;
  @Input() ppFieldCurrencyCode: string;
  @Input() ppPointCustomField: TPointCustomField;
  @Input() ppNew: boolean;
  @Input() ppCanEdit: boolean;
  @Input() ppVolyActive: EIntegrationStatus;
  @Input() ppSubfieldsActive: boolean;

  private readonly destroy$ = new Subject<void>();

  updating = false;
  success = false;
  error = false;
  focused = false;
  EIconPath = EIconPath;
  EIntegrationStatus = EIntegrationStatus;

  private cancelUpdateField$ = new Subject<void>();
  private updateFieldTimerMs = 500;
  private successTimerMs = 2500;

  constructor(
    private store: Store<{ points: TPoint[] }>,
    private promptService: PromptService,
    private pointFieldsService: PointFieldsService,
    private pointActivityService: PointActivityService,
    private sitePointFilterService: SitePointFilterService,
    private pointsUpdateService: PointsUpdateService,
    private translationPipe: TranslationPipe,
    private pointsService: PointsService,
    private customTableService: CustomTableService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ppPointId) {
      const point: TPoint = this.pointsService.findPoint(this.ppFieldId);

      if (point) {
        this.ppPointCustomField.value = point.customFieldsMap[this.ppFieldId]?.value;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  clearCustomField(): void {
    this.ppPointCustomField.value = null;
    this.updateField(null);
  }

  updateField(newValue: string): void {
    const _id = this.ppPointId;
    const fieldId = this.ppFieldId;
    const fieldValue = newValue;
    const workspaceId = this.ppWorkspaceId;

    this.cancelUpdateField$.next();

    timer(this.updateFieldTimerMs)
      .pipe(
        takeUntil(this.cancelUpdateField$),
        tap(() => {
          this.updateFieldDeferred(_id, fieldId, fieldValue, workspaceId);
        }),
      )
      .subscribe();
  }

  private updateFieldDeferred(
    _id: string,
    fieldId: string,
    fieldValue: string,
    workspaceId: string,
  ): void {
    if (this.ppNew) {
      this.updateFieldForNewPoint(_id, fieldId, fieldValue, workspaceId);

      return;
    }

    this.updating = true;

    const body: TPointUpdate = {
      customFieldsList: [
        {
          customFieldTemplateId: this.ppFieldId,
          value: trimNumericInput(fieldValue),
        },
      ],
    };

    this.pointsUpdateService
      .updatePointField(_id, body)
      .pipe(
        tap((response) => {
          this.success = true;
          const promptText = this.translationPipe.transform('prompt_point_cost_update');

          this.promptService.showSuccess(promptText);
          this.pointActivityService.refreshTimeline(workspaceId, _id);

          this.sitePointFilterService.filterPoints({ _keepScrollPosition: true });

          timer(this.successTimerMs).subscribe(() => {
            this.success = false;
          });
        }),
        catchError((error) => {
          this.pointFieldsService.showUpdatePointFieldError(error);

          this.error = true;

          return of();
        }),
        finalize(() => {
          this.updating = false;
        }),
      )
      .subscribe();
  }

  private updateFieldForNewPoint(
    pointId: string,
    fieldId: string,
    fieldValue: string,
    workspaceId: string,
  ): void {
    this.store.dispatch(
      new UpdatePointCustomField({
        workspaceId: workspaceId,
        pointId: pointId,
        customFieldId: fieldId,
        customFieldValue: fieldValue ? fieldValue.replace(/,/g, '') : fieldValue,
      }),
    );
  }
}
