<div class="subfieldTotal">
  <div class="subfieldTotal__inner">
    <section
      ppTooltip
      [ppTitle]="ppUnit"
      [ppTrimTooltip]="true"
      class="subfieldTotal__unit"
      [class.subfieldTotal__unit--hidden]="!ppUnit"
    >
      {{ ppUnit }}
    </section>

    <section class="subfieldTotal__value">
      <div class="subfieldTotal__value--inner">{{ formattedValue }}</div>

      <div
        class="subfieldTotal__value--inner subfieldTotal__value--placeholder"
        *ngIf="!formattedValue"
      >
        {{ placeholder }}
      </div>
    </section>
  </div>
  <div class="subfieldTotal__title">{{ 'total' | ppTranslate }}</div>
</div>
