<div class="pointCF__field">
  <label
    ppTooltip
    class="pointCF__label"
    ppTitle="{{ ppCustomField?.label }}"
    [ppTrimTooltip]="true"
  >
    <img
      class="pointCF__bulk-changes-field-icon"
      [src]="EIconPath.CUSTOM_FIELD_PERCENTAGE_ACTIVITY"
    />

    <span class="pointCF__label-container">
      {{ ppCustomField?.label }}
    </span>
  </label>

  <div
    class="pointCF__content"
    ppTooltip
    [ppTitle]="
      ppCustomField.subValuesActive
        ? ('prompt_bulk_changes_subfields_edit_disabled' | ppTranslate)
        : ''
    "
  >
    <pp-custom-field-percentage
      [ppCanEdit]="!ppCustomField.subValuesActive"
      [ppField]="ppCustomField"
      [ppValue]="ppCustomFields[ppFieldId]"
      [ppWorkspaceId]="ppWorkspaceId"
      [ppFieldId]="ppFieldId"
      (ppUpdate)="updatePercentage($event, ppCustomField)"
    ></pp-custom-field-percentage>
  </div>
</div>
